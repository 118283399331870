import { getCustomersWithNoOrg } from "../../config/config";
import {
	SET_IS_LINKED,
	GET_CALL_CUSTOMERS,
	GET_CALL_CUSTOMERS_SUCCESS,
	ADD_CUSTOMER_SUCCESS,
	ADD_CUSTOMER,
	GET_LINKED_CUSTOMERS,
	GET_LINKED_CUSTOMERS_SUCCESS,
	GET_ALL_CUSTOMERS_SUCCESS,
	GET_ALL_CUSTOMERS,
	GET_ALL_CUSTOMERS_LIST,
	GET_CUSTOMER_SUCCESS,
	GET_CUSTOMER,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER,
	DELETE_CUSTOMER_SUCCESS,
	ADD_CUSTOMER_OUTLET_SUCCESS,
	GET_ALL_CUSTOMER_OUTLETS_SUCCESS,
	GET_ALL_CUSTOMER_OUTLETS_LIST,
	GET_CUSTOMER_OUTLET_SUCCESS,
	UPDATE_CUSTOMER_OUTLET_SUCCESS,
	DELETE_CUSTOMER_OUTLET_SUCCESS,
	ADD_CUSTOMER_GROUP_SUCCESS,
	ADD_CUSTOMER_GROUP,
	GET_ALL_CUSTOMER_GROUPS_SUCCESS,
	GET_ALL_CUSTOMER_GROUPS,
	GET_CUSTOMER_GROUP_SUCCESS,
	GET_CUSTOMER_GROUP,
	UPDATE_CUSTOMER_GROUP_SUCCESS,
	UPDATE_CUSTOMER_GROUP,
	DELETE_CUSTOMER_GROUP_SUCCESS,
	ADD_CUSTOMER_PHONE_SUCCESS,
	ADD_CUSTOMER_PHONE,
	DELETE_CUSTOMER_PHONE_SUCCESS,
	EDIT_CUSTOMER_PHONE_SUCCESS,
	EDIT_CUSTOMER_PHONE,
	GET_CUSTOMER_PHONES_SUCCESS,
	GET_CUSTOMER_PHONES,
	GET_CUSTOMER_PHONE_SUCCESS,
	GET_CUSTOMER_PHONE,
	UPLOAD_LOGO,
	DELETE_CUSTOMER,
	DELETE_CUSTOMER_GROUP,
	DELETE_CUSTOMER_PHONE,
	GET_ALL_CUSTOMER_GROUPS_LIST,
	GET_ORGANISATIONS,
	ADD_ORGANISATION,
	ADD_ORGANISATION_LICENSE,
	GET_ORGANISATIONS_SUCCESS,
	GET_ORGANISATION,
	GET_ORGANISATION_SUCCESS,
	ADD_ORGANISATION_SUCCESS,
	ADD_ORGANISATION_LICENSE_SUCCESS,
	GET_RESTAURANTS_BRANCHES,
	GET_RESTAURANTS_BRANCHES_SUCCESS,
	ADD_RESTAURANT,
	ADD_RESTAURANT_SUCCESS,
	EDIT_RESTAURANT_SUCCESS,
	EDIT_RESTAURANT,
	ADD_REST_BRANCH,
	ADD_REST_BRANCH_SUCCESS,
	EDIT_REST_BRANCH,
	EDIT_REST_BRANCH_SUCCESS,
	UPLOAD_REST_LOGO,
	GET_CUSTOMER_GLOBAL_CONFIG_SUCCESS,
	GET_CUSTOMER_GLOBAL_CONFIG,
	GET_ORG_CUSTOMERS,
	GET_ORG_CUSTOMERS_SUCCESS,
	GET_CUSTOMERS_WITH_NO_ORG,
	GET_CUSTOMERS_WITH_NO_ORG_SUCCESS,
	GET_ALL_CUSTOMERS_FILTERS,
	GET_CUSTOMERS_STATES_SUCCESS,
	GET_CUSTOMERS_CITIES_SUCCESS

} from "../actions/Customers.action";
const RESET_LOADING = "RESET_LOADING"

const initialState = {
	addCustomerLoading: false,
	editCustomerLoading: false,
	getCustomerLoading: false,
	getCustomersLoading: false,
	getCustomersFilterLoading: false,
	getCallCustomersLoading: false,

	addCustomerGroupLoading: false,
	editCustomerGroupLoading: false,
	getCustomerGroupLoading: false,
	getCustomerGroupsLoading: false,

	addCustomerPhoneLoading: false,
	editCustomerPhoneLoading: false,
	getCustomerPhoneLoading: false,
	getCustomerPhonesLoading: false,

	deleteCustomerGroupLoading: false,
	deleteCustomerLoading: false,
	deleteCustomerPhoneLoading: false,

	addOrganisationLoading: false,
	getOrganisationsLoading: false,
	getOrganisationLoading: false,
	getCustomersWithNoOrgLoading: false,

	addRestaurantLoading: false,
	editRestaurantLoading: false,
	editRestaurantBranchLoading: false,
	addRestaurantBranchLoading: false,
	getRestaurantsLoading: false,
	getOrgCustomersLoading: false,

	getCustomerConfigLoading: false,
	addOrganisationLicenseLoading: false,

	getLinkedCustomersLoading: false,

	linkedCustomers: {},
	customerCurrencies: [],
	customerCountries: [],
	customersStates: [],
	customersCities: [],

	restaurants: [],

	serviceCallCustomers: [],
	customers: {},
	customersList: [],
	addedCustomer: "",
	currentCustomer: "",
	customerOutlets: [],
	customerOutletsList: [],
	currentCustomerOutlet: "",
	customerGroups: [],
	customerGroupsList: [],
	currentCustomerGroup: "",
	customerPhones: [],
	customerCurrentPhone: "",

	organisations: [],
	addedOrganisation: "",
	currentOrganisation: null,
	orgCustomers: [],

	addedGroup: "",
	logoUrl: "",
	restLogo: "",
	customersWithNoOrg: [],
	isLinked: false
};

function branch(state = initialState, action) {
	switch (action.type) {
		case RESET_LOADING:
			return Object.assign({}, state, {
				getLinkedCustomersLoading: false,

				addCustomerLoading: false,
				editCustomerLoading: false,
				getCustomerLoading: false,
				getCustomersLoading: false,
				getCustomersFilterLoading: false,
				getCallCustomersLoading: false,

				addOrganisationLoading: false,
				getOrganisationsLoading: false,
				getOrganisationLoading: false,
				addOrganisationLicenseLoading: false,

				addRestaurantLoading: false,
				editRestaurantLoading: false,
				editRestaurantBranchLoading: false,
				addRestaurantBranchLoading: false,
				getRestaurantsLoading: false,

				getOrgCustomersLoading: false,
				addCustomerGroupLoading: false,
				editCustomerGroupLoading: false,
				getCustomerGroupLoading: false,
				getCustomerGroupsLoading: false,

				addCustomerPhoneLoading: false,
				editCustomerPhoneLoading: false,
				getCustomerPhoneLoading: false,
				getCustomerPhonesLoading: false,

				deleteCustomerGroupLoading: false,
				deleteCustomerLoading: false,
				deleteCustomerPhoneLoading: false,

				getCustomerConfigLoading: false,
				getCustomersWithNoOrgLoading: false,

			})

		case UPLOAD_LOGO:
			return Object.assign({}, state, {
				logoUrl: action.payload.logo,
			});

		case UPLOAD_REST_LOGO:
			return Object.assign({}, state, {
				restLogo: action.payload.restLogo,
			});

		case GET_CUSTOMER_GLOBAL_CONFIG_SUCCESS:
			return Object.assign({}, state, {
				customerCountries: action.payload.customerCountries,
				customerCurrencies: action.payload.customerCurrencies,
			});
		case GET_CUSTOMER_GLOBAL_CONFIG:
			return Object.assign({}, state, {
				getCustomerConfigLoading: action.payload.getCustomerConfigLoading,
			});

		case GET_LINKED_CUSTOMERS_SUCCESS:
			const linkedCustomers = action.payload.linkedCustomers?.reduce((accumulator, currentValue, index) => {
				accumulator[currentValue.uuid] = currentValue;
				return accumulator;
			}, {})
			return Object.assign({}, state, {
				linkedCustomers: linkedCustomers,
			});
		case GET_LINKED_CUSTOMERS:
			return Object.assign({}, state, {
				getLinkedCustomersLoading: action.payload.getLinkedCustomersLoading,
			});

		case GET_RESTAURANTS_BRANCHES_SUCCESS:
			return Object.assign({}, state, {
				restaurants: action.payload.restaurants,
				currentOrganisation:action.payload.currentOrganisation
			});
		case GET_RESTAURANTS_BRANCHES:
			return Object.assign({}, state, {
				getRestaurantsLoading: action.payload.getRestaurantsLoading,
			});

		case ADD_RESTAURANT_SUCCESS:
			return Object.assign({}, state, {
				restaurants: action.payload.restaurants,
			});
		case ADD_RESTAURANT:
			return Object.assign({}, state, {
				addRestaurantLoading: action.payload.addRestaurantLoading,
			});

		case EDIT_RESTAURANT_SUCCESS:
			return Object.assign({}, state, {
				restaurants: action.payload.restaurants,
			});

		case EDIT_RESTAURANT:
			return Object.assign({}, state, {
				editRestaurantLoading: action.payload.editRestaurantLoading,
			});


		case ADD_REST_BRANCH_SUCCESS:
			return Object.assign({}, state, {
				restaurants: action.payload.restaurants,
			});
		case ADD_REST_BRANCH:
			return Object.assign({}, state, {
				addRestaurantBranchLoading: action.payload.addRestaurantBranchLoading,
			});

		case EDIT_REST_BRANCH_SUCCESS:
			return Object.assign({}, state, {
				restaurants: action.payload.restaurants,
			});

		case EDIT_REST_BRANCH:
			return Object.assign({}, state, {
				editRestaurantBranchLoading: action.payload.editRestaurantBranchLoading,
			});


		case ADD_CUSTOMER_SUCCESS:
			return Object.assign({}, state, {
				customers: action.payload.customers,
				addedCustomer: action.payload.addedCustomer
			});
		case ADD_CUSTOMER:
			return Object.assign({}, state, {
				addCustomerLoading: action.payload.addCustomerLoading,
			});

		case ADD_ORGANISATION_LICENSE_SUCCESS:
			return Object.assign({}, state, {
				organisations: action.payload.organisations,
				addedOrganisation: action.payload.addedOrganisation
			});
		case ADD_ORGANISATION_LICENSE:
			return Object.assign({}, state, {
				addOrganisationLicenseLoading: action.payload.addOrganisationLicenseLoading,
			});


		case ADD_ORGANISATION_SUCCESS:
			return Object.assign({}, state, {
				organisations: action.payload.organisations,
				addedOrganisation: action.payload.addedOrganisation
			});
		case ADD_ORGANISATION:
			return Object.assign({}, state, {
				addOrganisationLoading: action.payload.addOrganisationLoading,
			});


		case GET_ORG_CUSTOMERS_SUCCESS:
			return Object.assign({}, state, {
				orgCustomers: action.payload.orgCustomers,
			});
		case GET_ORG_CUSTOMERS:
			return Object.assign({}, state, {
				getOrgCustomersLoading: action.payload.getOrgCustomersLoading,
			});
		case GET_ORGANISATIONS_SUCCESS:
			return Object.assign({}, state, {
				organisations: action.payload.organisations,
			});
		case GET_ORGANISATIONS:
			return Object.assign({}, state, {
				getOrganisationsLoading: action.payload.getOrganisationsLoading,
			});
		case GET_ORGANISATION_SUCCESS:
			return Object.assign({}, state, {
				currentOrganisation: action.payload.currentOrganisation,
			});
		case GET_ORGANISATION:
			return Object.assign({}, state, {
				getOrganisationLoading: action.payload.getOrganisationLoading,
			});

		case GET_CUSTOMERS_STATES_SUCCESS:
			return Object.assign({}, state, {
				customersStates: action.payload.customersStates,
				customersCities: action.payload.customersCities,
			});

		case GET_CUSTOMERS_CITIES_SUCCESS:
			return Object.assign({}, state, {
				customersCities: action.payload.customersCities,
				customersStates: action.payload.customersStates,
			});

		case GET_ALL_CUSTOMERS_SUCCESS:

			let copyCustomers = [];
			if (action.payload.page === 1 || !action.payload.view) {
				copyCustomers = [...action.payload.customers]
			}
			else {
				copyCustomers = [...state?.customers?.data, ...action.payload.customers]

			}
			return Object.assign({}, state, {
				customers: {
					data: [...copyCustomers],
					totalRecords: action.payload.totalRecords,
					totalPages: action.payload.totalPages,
					page: action.payload.page
				}
			});
		case GET_CUSTOMERS_WITH_NO_ORG_SUCCESS:
			return Object.assign({}, state, {
				customersWithNoOrg: action.payload.customers,
			});
		case GET_ALL_CUSTOMERS_LIST:
			return Object.assign({}, state, {
				customersList: action.payload.customersList,
			});
		case GET_ALL_CUSTOMERS:
			return Object.assign({}, state, {
				getCustomersLoading: action.payload.getCustomersLoading,

			});
		case GET_ALL_CUSTOMERS_FILTERS:
			return Object.assign({}, state, {
				getCustomersFilterLoading: action.payload.getCustomersFilterLoading,

			});
		case GET_CUSTOMERS_WITH_NO_ORG:
			return Object.assign({}, state, {
				getCustomersWithNoOrgLoading: action.payload.getCustomersWithNoOrgLoading,

			});
		case GET_CALL_CUSTOMERS:
			return Object.assign({}, state, {
				getCallCustomersLoading: action.payload.getCallCustomersLoading,

			});
		case GET_CALL_CUSTOMERS_SUCCESS:
			return Object.assign({}, state, {
				serviceCallCustomers: action.payload.customers,
			});

		case GET_CUSTOMER_SUCCESS:
			return Object.assign({}, state, {
				currentCustomer: action.payload.currentCustomer,
				logoUrl: action.payload.logoUrl
			});

		case GET_CUSTOMER:
			return Object.assign({}, state, {
				getCustomerLoading: action.payload.getCustomerLoading,
			});

		case UPDATE_CUSTOMER_SUCCESS:
			return Object.assign({}, state, {
				customers: action.payload.customers,
			});
		case UPDATE_CUSTOMER:
			return Object.assign({}, state, {
				editCustomerLoading: action.payload.editCustomerLoading,
			});

		case DELETE_CUSTOMER_SUCCESS:
			return Object.assign({}, state, {
				customers: action.payload.customers,
			});
		case DELETE_CUSTOMER:
			return Object.assign({}, state, {
				deleteCustomerLoading: action.payload.deleteCustomerLoading,
			});

		case ADD_CUSTOMER_OUTLET_SUCCESS:
			return Object.assign({}, state, {
				customerOutlets: action.payload.customerOutlets,
			});

		case GET_ALL_CUSTOMER_OUTLETS_SUCCESS:
			return Object.assign({}, state, {
				customerOutlets: action.payload.customerOutlets,
				currentCustomerOutlet: "",
			});
		case GET_ALL_CUSTOMER_OUTLETS_LIST:
			return Object.assign({}, state, {
				customerOutletsList: action.payload.customerOutletsList,
				currentCustomerOutlet: "",
			});


		case GET_CUSTOMER_OUTLET_SUCCESS:
			return Object.assign({}, state, {
				currentCustomerOutlet: action.payload.currentCustomerOutlet,
			});

		case UPDATE_CUSTOMER_OUTLET_SUCCESS:
			return Object.assign({}, state, {
				customerOutlets: action.payload.customerOutlets,
			});

		case DELETE_CUSTOMER_OUTLET_SUCCESS:
			return Object.assign({}, state, {
				customerOutlets: action.payload.customerOutlets,
			});

		case ADD_CUSTOMER_GROUP_SUCCESS:
			return Object.assign({}, state, {
				customerGroups: action.payload.customerGroups,
				addedGroup: action.payload.addedGroup
			});

		case ADD_CUSTOMER_GROUP:
			return Object.assign({}, state, {
				addCustomerGroupLoading: action.payload.addCustomerGroupLoading,
			});

		case GET_ALL_CUSTOMER_GROUPS_SUCCESS:
			return Object.assign({}, state, {
				customerGroups: action.payload.customerGroups,
				currentCustomerGroup: "",
			});

		case GET_ALL_CUSTOMER_GROUPS_LIST:
			return Object.assign({}, state, {
				customerGroupsList: action.payload.customerGroupsList,
				currentCustomerGroup: "",
			});

		case GET_ALL_CUSTOMER_GROUPS:
			return Object.assign({}, state, {
				getCustomerGroupsLoading: action.payload.getCustomerGroupsLoading,
			});

		case GET_CUSTOMER_GROUP_SUCCESS:
			return Object.assign({}, state, {
				currentCustomerGroup: action.payload.currentCustomerGroup,
			});

		case GET_CUSTOMER_GROUP:
			return Object.assign({}, state, {
				getCustomerGroupLoading: action.payload.getCustomerGroupLoading,
			});

		case UPDATE_CUSTOMER_GROUP_SUCCESS:
			return Object.assign({}, state, {
				customerGroups: action.payload.customerGroups,
			});
		case UPDATE_CUSTOMER_GROUP:
			return Object.assign({}, state, {
				editCustomerGroupLoading: action.payload.editCustomerGroupLoading,
			});

		case DELETE_CUSTOMER_GROUP_SUCCESS:
			return Object.assign({}, state, {
				customerGroups: action.payload.customerGroups,
			});
		case DELETE_CUSTOMER_GROUP:
			return Object.assign({}, state, {
				deleteCustomerGroupLoading: action.payload.deleteCustomerGroupLoading,
			});
		case ADD_CUSTOMER_PHONE_SUCCESS:
			return Object.assign({}, state, {
				customerPhones: action.payload.customerPhones,
			});
		case ADD_CUSTOMER_PHONE:
			return Object.assign({}, state, {
				addCustomerPhoneLoading: action.payload.addCustomerPhoneLoading,
			});
		case EDIT_CUSTOMER_PHONE_SUCCESS:
			return Object.assign({}, state, {
				customerPhones: action.payload.customerPhones,
			});
		case EDIT_CUSTOMER_PHONE:
			return Object.assign({}, state, {
				editCustomerPhoneLoading: action.payload.editCustomerPhoneLoading,
			});

		case DELETE_CUSTOMER_PHONE_SUCCESS:
			return Object.assign({}, state, {
				customerPhones: action.payload.customerPhones,
			});
		case DELETE_CUSTOMER_PHONE:
			return Object.assign({}, state, {
				deleteCustomerPhoneLoading: action.payload.deleteCustomerPhoneLoading,
			});

		case GET_CUSTOMER_PHONES_SUCCESS:
			return Object.assign({}, state, {
				customerPhones: action.payload.customerPhones,
				customerCurrentPhone: "",
			});
		case GET_CUSTOMER_PHONES:
			return Object.assign({}, state, {
				getCustomerPhonesLoading: action.payload.getCustomerPhonesLoading,

			});

		case GET_CUSTOMER_PHONE_SUCCESS:
			return Object.assign({}, state, {
				customerCurrentPhone: action.payload.customerCurrentPhone,
			});

		case GET_CUSTOMER_PHONE:
			return Object.assign({}, state, {
				getCustomerPhoneLoading: action.payload.getCustomerPhoneLoading,

			});

		default:
			return state;
	}
}

export default branch;
