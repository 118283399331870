import {
	getCustomerGlobalConfig,
	addCustomer,
	getCallCustomers,
	getAllCustomers,
	getAllCustomersList,
	getCustomer,
	updateCustomer,
	deleteCustomer,
	addCustomerOutlet,
	getAllCustomerOutlets,
	getAllCustomerOutletsList,
	getCustomerOutlet,
	updateCustomerOutlet,
	deleteCustomerOutlet,
	addCustomerGroup,
	getAllCustomerGroups,
	getCustomerGroup,
	updateCustomerGroup,
	deleteCustomerGroup,
	addCustomerPhone,
	editCustomerPhone,
	getCustomerPhones,
	getCustomerPhone,
	deleteCustomerPhone,
	getAllCustomerGroupsList,
	addOrganisation,
	getAllOrganisations,
	getOrganisations,
	getRestaurantsBranches,
	editRestBranch,
	editRestaurant,
	addRestBranch,
	addRestaurant,
	getOrgRestaurantsBranches,
	getOrganisation,
	getOrgCustomers,
	addOrganisationLicense,
	getCustomersWithNoOrg,
	getCustomersCities,
	getCustomersStates,
	getLinkedCustomers
} from "../../config/config";

import { toast } from "react-toastify";


export const GET_CUSTOMERS_CITIES_SUCCESS = "GET_CUSTOMERS_CITIES_SUCCESS"
export const GET_CUSTOMERS_STATES_SUCCESS = "GET_CUSTOMERS_STATES_SUCCESS"
export const ADD_ORGANISATION = "ADD_ORGANISATION";
export const ADD_ORGANISATION_LICENSE = "ADD_ORGANISATION_LICENSE";
export const GET_ORGANISATIONS = "GET_ORGANISATIONS";
export const GET_ORGANISATION = "GET_ORGANISATION";
export const GET_ORG_CUSTOMERS = "GET_ORG_CUSTOMERS";
export const GET_CUSTOMERS_WITH_NO_ORG = "GET_CUSTOMERS_WITH_NO_ORG";
export const GET_LINKED_CUSTOMERS = "GET_LINKED_CUSTOMERS";

export const GET_RESTAURANTS_BRANCHES = "GET_RESTAURANTS_BRANCHES"
export const EDIT_REST_BRANCH = "EDIT_REST_BRANCH";
export const EDIT_RESTAURANT = "EDIT_RESTAURANT";

export const ADD_REST_BRANCH = "ADD_REST_BRANCH";
export const ADD_RESTAURANT = "ADD_RESTAURANT";

export const GET_RESTAURANTS_BRANCHES_SUCCESS = "GET_RESTAURANTS_BRANCHES_SUCCESS"
export const EDIT_REST_BRANCH_SUCCESS = "EDIT_REST_BRANCH_SUCCESS";
export const EDIT_RESTAURANT_SUCCESS = "EDIT_RESTAURANT_SUCCESS";
export const GET_ORG_CUSTOMERS_SUCCESS = "GET_ORG_CUSTOMERS_SUCCESS";

export const ADD_REST_BRANCH_SUCCESS = "ADD_REST_BRANCH_SUCCESS";
export const ADD_RESTAURANT_SUCCESS = "ADD_RESTAURANT_SUCCESS";

export const ADD_ORGANISATION_SUCCESS = "ADD_ORGANISATION_SUCCESS";
export const ADD_ORGANISATION_LICENSE_SUCCESS = "ADD_ORGANISATION_LICENSE_SUCCESS";
export const GET_ORGANISATIONS_SUCCESS = "GET_ORGANISATIONS_SUCCESS";
export const GET_ORGANISATION_SUCCESS = "GET_ORGANISATION_SUCCESS";
export const GET_CUSTOMERS_WITH_NO_ORG_SUCCESS = "GET_CUSTOMERS_WITH_NO_ORG_SUCCESS";
export const GET_LINKED_CUSTOMERS_SUCCESS = "GET_LINKED_CUSTOMERS_SUCCESS";

export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

export const GET_ALL_CUSTOMERS_LIST = "GET_ALL_CUSTOMERS_LIST";
export const GET_CALL_CUSTOMERS = "GET_CALL_CUSTOMERS";
export const GET_CALL_CUSTOMERS_SUCCESS = "GET_CALL_CUSTOMERS_SUCCESS";

export const ADD_CUSTOMER_OUTLET_SUCCESS = "ADD_CUSTOMER_OUTLET_SUCCESS";
export const GET_ALL_CUSTOMER_OUTLETS_SUCCESS = "GET_ALL_CUSTOMER_OUTLETS_SUCCESS";
export const GET_CUSTOMER_OUTLET_SUCCESS = "GET_CUSTOMER_OUTLET_SUCCESS";
export const UPDATE_CUSTOMER_OUTLET_SUCCESS = "UPDATE_CUSTOMER_OUTLET_SUCCESS";
export const DELETE_CUSTOMER_OUTLET_SUCCESS = "DELETE_CUSTOMER_OUTLET_SUCCESS";

export const GET_ALL_CUSTOMER_OUTLETS_LIST = "GET_ALL_CUSTOMER_OUTLETS_LIST";

export const ADD_CUSTOMER_GROUP_SUCCESS = "ADD_CUSTOMER_GROUP_SUCCESS";
export const GET_ALL_CUSTOMER_GROUPS_SUCCESS = "GET_ALL_CUSTOMER_GROUPS_SUCCESS";
export const GET_CUSTOMER_GROUP_SUCCESS = "GET_CUSTOMER_GROUP_SUCCESS";
export const UPDATE_CUSTOMER_GROUP_SUCCESS = "UPDATE_CUSTOMER_GROUP_SUCCESS";
export const DELETE_CUSTOMER_GROUP_SUCCESS = "DELETE_CUSTOMER_GROUP_SUCCESS";

export const ADD_CUSTOMER_GROUP = "ADD_CUSTOMER_GROUP";
export const GET_ALL_CUSTOMER_GROUPS = "GET_ALL_CUSTOMER_GROUPS";
export const GET_ALL_CUSTOMER_GROUPS_LIST = "GET_ALL_CUSTOMER_GROUPS_LIST";
export const GET_CUSTOMER_GROUP = "GET_CUSTOMER_GROUP";
export const UPDATE_CUSTOMER_GROUP = "UPDATE_CUSTOMER_GROUP";
export const DELETE_CUSTOMER_GROUP = "DELETE_CUSTOMER_GROUP";

export const ADD_CUSTOMER_PHONE_SUCCESS = "ADD_CUSTOMER_PHONE_SUCCESS";
export const EDIT_CUSTOMER_PHONE_SUCCESS = "EDIT_CUSTOMER_PHONE_SUCCESS";
export const GET_CUSTOMER_PHONES_SUCCESS = "GET_CUSTOMER_PHONES_SUCCESS";
export const GET_CUSTOMER_PHONE_SUCCESS = "GET_CUSTOMER_PHONE_SUCCESS";
export const DELETE_CUSTOMER_PHONE_SUCCESS = "DELETE_CUSTOMER_PHONE_SUCCESS";

export const ADD_CUSTOMER_PHONE = "ADD_CUSTOMER_PHONE";
export const EDIT_CUSTOMER_PHONE = "EDIT_CUSTOMER_PHONE";
export const GET_CUSTOMER_PHONES = "GET_CUSTOMER_PHONES";
export const GET_CUSTOMER_PHONE = "GET_CUSTOMER_PHONE";
export const DELETE_CUSTOMER_PHONE = "DELETE_CUSTOMER_PHONE";

export const UPLOAD_LOGO = "UPLOAD_LOGO";

export const UPLOAD_REST_LOGO = "UPLOAD_REST_LOGO";

export const GET_CUSTOMER_GLOBAL_CONFIG = "GET_CUSTOMER_GLOBAL_CONFIG";
export const GET_CUSTOMER_GLOBAL_CONFIG_SUCCESS = "GET_CUSTOMER_GLOBAL_CONFIG_SUCCESS";
export const GET_ALL_CUSTOMERS_FILTERS = "GET_ALL_CUSTOMERS_FILTERS";


const getCustomers = (page, limit, search, isActive, sortBy, cities, states, customerGroups, loading = false, dispatch, view, setPage, setFailedPage) => {
	if (loading)
		dispatch({
			type: GET_ALL_CUSTOMERS_FILTERS,
			payload: {
				getCustomersFilterLoading: true,
			},
		});
	else
		dispatch({
			type: GET_ALL_CUSTOMERS,
			payload: {
				getCustomersLoading: true,
			},
		});
	getAllCustomers(page, limit, search, isActive, sortBy, cities, states, customerGroups).then((res) => {
		if (loading)
			dispatch({
				type: GET_ALL_CUSTOMERS_FILTERS,
				payload: {
					getCustomersFilterLoading: false,
				},
			});
		else
			dispatch({
				type: GET_ALL_CUSTOMERS,
				payload: {
					getCustomersLoading: false
				},
			});

		if (res?.status === 200) {
			if (typeof setPage === 'function' && view) {

				setPage();
			}
			if (typeof setPage === 'function' && !view) {
				setPage();
			}
			dispatch({
				type: "SET_PAGE_DETAILS",
				payload: {
					page: "customers",
					currentPage: res?.data?.currentPage,
					totalPages: res?.data?.totalPages,
					totalRecords: res?.data?.totalRecords
				},
			});
			dispatch({
				type: GET_ALL_CUSTOMERS_SUCCESS,
				payload: {
					customers: res?.data?.customers,
					page: res?.data?.currentPage,
					totalPages: res?.data?.totalPages,
					totalRecords: res?.data?.totalRecords,
					view
				},
			});
		} else if (res?.status === 401) {
			window.location = "/session-ended";
		} else if (res?.status === 403) {
			window.location = "/forbidden";
		} else {
			toast.error(res?.data?.message, { theme: "colored" });
		}
		if (res?.status != 200) {
			setFailedPage(page)
		}

	}).catch(() => setFailedPage(page));
};

const actions = {
	uploadLogo: (data) => (dispatch) => {
		dispatch({
			type: UPLOAD_LOGO,
			payload: {
				logo: data,
			},
		});
	},

	uploadRestLogo: (data) => (dispatch) => {
		dispatch({
			type: UPLOAD_REST_LOGO,
			payload: {
				restLogo: data,
			},
		});
	},


	//GET LINKED CUSTOMERS
	getLinkedCustomers: (id) => (dispatch) => {
		dispatch({
			type: GET_LINKED_CUSTOMERS,
			payload: {
				getLinkedCustomersLoading: true,
			},
		});
		getLinkedCustomers(id).then((res) => {
			dispatch({
				type: GET_LINKED_CUSTOMERS,
				payload: {
					getLinkedCustomersLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_LINKED_CUSTOMERS_SUCCESS,
					payload: {
						linkedCustomers: res?.data?.linkedCustomers,

					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//GET CUST GLOBAL CONFIG
	getCustomerGlobalConfig: (id) => (dispatch) => {
		dispatch({
			type: GET_CUSTOMER_GLOBAL_CONFIG,
			payload: {
				getCustomerConfigLoading: true,
			},
		});
		getCustomerGlobalConfig(id).then((res) => {
			dispatch({
				type: GET_CUSTOMER_GLOBAL_CONFIG,
				payload: {
					getCustomerConfigLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMER_GLOBAL_CONFIG_SUCCESS,
					payload: {
						customerCurrencies: res?.data?.currencies,
						customerCountries: res?.data?.countries
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


	//GET RESTAURANTS AND BRANCHES
	getRestaurants: (id) => (dispatch) => {
		dispatch({
			type: GET_RESTAURANTS_BRANCHES,
			payload: {
				getRestaurantsLoading: true,
			},
		});
		getRestaurantsBranches(id).then((res) => {
			dispatch({
				type: GET_RESTAURANTS_BRANCHES,
				payload: {
					getRestaurantsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_RESTAURANTS_BRANCHES_SUCCESS,
					payload: {
						restaurants: res?.data?.restaurants,
						currentOrganisation: res?.data?.organisation
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET RESTAURANTS AND BRANCHES
	getOrgRestaurants: (id) => (dispatch) => {
		dispatch({
			type: GET_RESTAURANTS_BRANCHES,
			payload: {
				getRestaurantsLoading: true,
			},
		});
		getOrgRestaurantsBranches(id).then((res) => {
			dispatch({
				type: GET_RESTAURANTS_BRANCHES,
				payload: {
					getRestaurantsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_RESTAURANTS_BRANCHES_SUCCESS,
					payload: {
						restaurants: res?.data?.restaurants,
						currentOrganisation: res?.data?.organisation
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	getOrgCustomers: (id) => (dispatch) => {
		dispatch({
			type: GET_ORG_CUSTOMERS,
			payload: {
				getOrgCustomersLoading: true,
			},
		});
		getOrgCustomers(id).then((res) => {
			dispatch({
				type: GET_ORG_CUSTOMERS,
				payload: {
					getOrgCustomersLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ORG_CUSTOMERS_SUCCESS,
					payload: {
						orgCustomers: res?.data?.customers
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD RESTAURANT
	addRestaurant: (data, customerId, history, promiseData, customer, orgId) => (dispatch) => {
		dispatch({
			type: ADD_RESTAURANT,
			payload: {
				addRestaurantLoading: true,
			},
		});
		const promises = promiseData?.map(({ promise }) => promise);

		Promise.all(promises)
			.then((res) => {
				promiseData?.forEach(({ part }, index) => {
					if (res[index]?.status === 200) {
						if (part === "logo")
							data[`logo`] = res[index]?.data?.fileName;
						else {
							data["banners"] = [...data["banners"], res[index]?.data?.fileName]
						}

					}
				});

				addRestaurant(data, customerId).then((res) => {
					dispatch({
						type: ADD_RESTAURANT,
						payload: {
							addRestaurantLoading: false,
						},
					});

					if (res?.status === 200) {

						dispatch({
							type: ADD_RESTAURANT_SUCCESS,
							payload: {
								restaurants: res?.data?.restaurants,
							},
						});
						toast.success(res?.data?.message || "Success", { theme: "colored" });
						if (customer)
							history.push(`/edit-restaurant/restId=${res?.data?.addedRestaurant}&customerId=${customerId}`)
						else
							history.push(`/edit-restaurant/restId=${res?.data?.addedRestaurant}&organisationId=${orgId}&customerId=${customerId}`)


					} else if (res?.status === 401) {
						window.location = "/session-ended";
					} else if (res?.status === 403) {
						window.location = "/forbidden";
					} else {
						toast.error(res?.data?.message || "error", { theme: "colored" });
					}
				});

			})
			.catch((error) => {
				console.error(error);
			});


	},

	//EDIT RESTAURANT
	editRestaurant: (data, restId, promiseData) => (dispatch) => {
		dispatch({
			type: EDIT_RESTAURANT,
			payload: {
				editRestaurantLoading: true,
			},
		});

		const promises = promiseData?.map(({ promise }) => promise);
		Promise.all(promises)
			.then((res) => {
				promiseData?.forEach(({ part }, index) => {
					if (res[index]?.status === 200) {
						if (part === "logo")
							data[`logo`] = res[index]?.data?.fileName;
						else {
							data["banners"] = [...data["banners"], res[index]?.data?.fileName]
						}

					}
				});

				editRestaurant(data, restId).then((res) => {
					dispatch({
						type: EDIT_RESTAURANT,
						payload: {
							editRestaurantLoading: false,
						},
					});

					if (res?.status === 200) {

						dispatch({
							type: EDIT_RESTAURANT_SUCCESS,
							payload: {
								restaurants: res?.data?.restaurants,
							},
						});
						toast.success(res?.data?.message || "Success", { theme: "colored" });
					} else if (res?.status === 401) {
						window.location = "/session-ended";
					} else if (res?.status === 403) {
						window.location = "/forbidden";
					} else {
						toast.error(res?.data?.message || "error", { theme: "colored" });
					}
				});

			})
			.catch((error) => {
				console.error(error);
			});
	},

	//ADD RESTAURANT BRANCH
	addRestaurantBranch: (data, customerId, restId, orgId, history) => (dispatch) => {
		dispatch({
			type: ADD_REST_BRANCH,
			payload: {
				addRestaurantBranchLoading: true,
			},
		});

		addRestBranch(data, customerId, restId).then((res) => {
			dispatch({
				type: ADD_REST_BRANCH,
				payload: {
					addRestaurantBranchLoading: false,
				},
			});

			if (res?.status === 200) {

				dispatch({
					type: ADD_REST_BRANCH_SUCCESS,
					payload: {
						restaurants: res?.data?.restaurants,
					},
				});
				history.push(`/edit-branch/restId=${restId}&organisationId=${orgId}/branchId=${res?.data?.addedBranhch}`)
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//EDIT RESTAURANT BRANCH
	editRestaurantBranch: (data, branchId, history) => (dispatch) => {
		dispatch({
			type: EDIT_REST_BRANCH,
			payload: {
				editRestaurantBranchLoading: true,
			},
		});

		editRestBranch(data, branchId).then((res) => {
			dispatch({
				type: EDIT_REST_BRANCH,
				payload: {
					editRestaurantBranchLoading: false,
				},
			});

			if (res?.status === 200) {

				dispatch({
					type: EDIT_REST_BRANCH_SUCCESS,
					payload: {
						restaurants: res?.data?.restaurants,
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET  ORGANISATION
	getOrganisation: (id) => (dispatch) => {
		dispatch({
			type: GET_ORGANISATION,
			payload: {
				getOrganisationsLoading: true,
			},
		});
		getOrganisation(id).then((res) => {
			dispatch({
				type: GET_ORGANISATION,
				payload: {
					getOrganisationLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ORGANISATION_SUCCESS,
					payload: {
						currentOrganisation: res?.data?.organisation
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//GET ALL ORGANISATIONS
	getOrganisations: () => (dispatch) => {
		dispatch({
			type: GET_ORGANISATIONS,
			payload: {
				getOrganisationsLoading: true,
			},
		});
		getOrganisations().then((res) => {
			dispatch({
				type: GET_ORGANISATIONS,
				payload: {
					getOrganisationsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ORGANISATIONS_SUCCESS,
					payload: {
						organisations: res?.data?.organisations
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//GET ALL ORGANISATIONS
	getAllOrganisations: (id) => (dispatch) => {
		dispatch({
			type: GET_ORGANISATIONS,
			payload: {
				getOrganisationsLoading: true,
			},
		});
		getAllOrganisations(id).then((res) => {
			dispatch({
				type: GET_ORGANISATIONS,
				payload: {
					getOrganisationsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ORGANISATIONS_SUCCESS,
					payload: {
						organisations: res?.data?.organisations
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD ORGANISATION
	addOrganisation: (data, customerId) => (dispatch) => {
		dispatch({
			type: ADD_ORGANISATION,
			payload: {
				addOrganisationLoading: true,
			},
		});

		addOrganisation(data, customerId).then((res) => {
			dispatch({
				type: ADD_ORGANISATION,
				payload: {
					addOrganisationLoading: false,
				},
			});

			if (res?.status === 200) {

				dispatch({
					type: ADD_ORGANISATION_SUCCESS,
					payload: {
						organisations: res?.data?.organisations,
						addedOrganisation: res?.data?.addedOrganisation
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD ORGANISATION
	addOrganisation: (data) => (dispatch) => {
		dispatch({
			type: ADD_ORGANISATION_LICENSE,
			payload: {
				addOrganisationLicenseLoading: true,
			},
		});

		addOrganisationLicense(data).then((res) => {
			dispatch({
				type: ADD_ORGANISATION_LICENSE,
				payload: {
					addOrganisationLicenseLoading: false,
				},
			});

			if (res?.status === 200) {

				dispatch({
					type: ADD_ORGANISATION_LICENSE_SUCCESS,
					payload: {
						organisations: res?.data?.organisations,
						addedOrganisation: res?.data?.addedOrganisation
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},



	//ADD CUTOMER
	addCustomer: (data, history) => (dispatch) => {
		dispatch({
			type: ADD_CUSTOMER,
			payload: {
				addCustomerLoading: true,
			},
		});

		addCustomer(data).then((res) => {
			dispatch({
				type: ADD_CUSTOMER,
				payload: {
					addCustomerLoading: false,
				},
			});

			if (res?.status === 200) {

				dispatch({
					type: ADD_CUSTOMER_SUCCESS,
					payload: {
						customers: res?.data?.customers,
						addedCustomer: res?.data?.addedCustomer
					},
				});
				history?.push("/customers");
				toast.success("Customer added", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL CUTOMERS WITH NO ORG
	getCustomersCities: () => (dispatch) => {

		getCustomersCities().then((res) => {

			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMERS_CITIES_SUCCESS,
					payload: {
						customersCities: res?.data?.cities,
						customersStates: res?.data?.states,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	getCustomersStates: () => (dispatch) => {

		getCustomersStates().then((res) => {

			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMERS_STATES_SUCCESS,
					payload: {
						customersStates: res?.data?.states,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	getCustomersWithNoOrg: () => (dispatch) => {
		dispatch({
			type: GET_CUSTOMERS_WITH_NO_ORG,
			payload: {
				getCustomersWithNoOrgLoading: true,
			},
		});
		getCustomersWithNoOrg().then((res) => {
			dispatch({
				type: GET_CUSTOMERS_WITH_NO_ORG,
				payload: {
					getCustomersWithNoOrgLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMERS_WITH_NO_ORG_SUCCESS,
					payload: {
						customers: res?.data?.customers,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},
	//FETCH ALL CUTOMERS
	getAllCustomers: (page, limit, search, isActive, sortBy, cities, states, customerGroups, controller, loading = false, view, setPage, setFailedPage) => (dispatch) => {

		getCustomers(page, limit, search, isActive, sortBy, cities, states, customerGroups, loading, dispatch, view, setPage, setFailedPage)
	},
	//FETCH ALL CUTOMERS
	getCallCustomers: () => (dispatch) => {
		dispatch({
			type: GET_CALL_CUSTOMERS,
			payload: {
				getCallCustomersLoading: true,
			},
		});
		getCallCustomers().then((res) => {
			dispatch({
				type: GET_CALL_CUSTOMERS,
				payload: {
					getCallCustomersLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CALL_CUSTOMERS_SUCCESS,
					payload: {
						customers: res?.data?.customers,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL CUTOMERS
	getAllCustomersList: () => (dispatch) => {

		getAllCustomersList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_CUSTOMERS_LIST,
					payload: {
						customersList: res?.data?.customers,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("customers")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE CUTOMER
	getCustomer: (id) => (dispatch) => {
		dispatch({
			type: GET_CUSTOMER,
			payload: {
				getCustomerLoading: true,
			},
		});

		getCustomer(id).then((res) => {
			dispatch({
				type: GET_CUSTOMER,
				payload: {
					getCustomerLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMER_SUCCESS,
					payload: {
						currentCustomer: res?.data?.customer,
						logoUrl: res?.data?.customer?.logo

					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE CUTOMER
	updateCustomer: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_CUSTOMER,
			payload: {
				editCustomerLoading: true,
			},
		});

		updateCustomer(data, id).then((res) => {
			dispatch({
				type: UPDATE_CUSTOMER,
				payload: {
					editCustomerLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_CUSTOMER_SUCCESS,
					payload: {
						customers: res?.data?.customers,
					},
				});
				history.push("/customers");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE CUTOMER
	deleteCustomer: (id, page, limit, search, isActive, sortBy, cities, states, customerGroups, view) => (dispatch) => {
		dispatch({
			type: DELETE_CUSTOMER,
			payload: {
				deleteCustomerLoading: true,
			},
		});
		deleteCustomer(id).then((res) => {
			dispatch({
				type: DELETE_CUSTOMER,
				payload: {
					deleteCustomerLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_CUSTOMER_SUCCESS,
					payload: {
						customers: res?.data?.customers,
					},
				});
				getCustomers(page, limit, search, isActive, sortBy, cities, states, customerGroups, false, dispatch, false, view)

			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD CUSTOMER OUTLET
	addCustomerOutlet: (data, history) => (dispatch) => {


		addCustomerOutlet(data).then((res) => {


			if (res?.status === 200) {
				dispatch({
					type: ADD_CUSTOMER_OUTLET_SUCCESS,
					payload: {
						customerOutlets: res?.data?.outlets,
					},
				});
				history.push("/customer-outlets");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL CUSTOMER OUTLETS
	getAllCustomerOutlets: () => (dispatch) => {
		getAllCustomerOutlets().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_CUSTOMER_OUTLETS_SUCCESS,
					payload: {
						customerOutlets: res?.data?.outlets,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL CUSTOMER OUTLETS LIST
	getAllCustomerOutletsList: () => (dispatch) => {
		getAllCustomerOutletsList().then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_CUSTOMER_OUTLETS_LIST,
					payload: {
						customerOutletsList: res?.data?.outlets,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				toast.error("customer outlets")
				// window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE CUSTOMER OUTLET
	getCustomerOutlet: (id) => (dispatch) => {
		getCustomerOutlet(id).then((res) => {
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMER_OUTLET_SUCCESS,
					payload: {
						currentCustomerOutlet: res?.data?.outlet,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE CUSTOMER OUTLET
	updateCustomerOutlet: (data, id, history) => (dispatch) => {


		updateCustomerOutlet(data, id).then((res) => {


			if (res?.status === 200) {
				dispatch({
					type: UPDATE_CUSTOMER_OUTLET_SUCCESS,
					payload: {
						customerOutlets: res?.data?.outlets,
					},
				});
				history.push("/customer-outlets");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE CUSTOMER OUTLET
	deleteCustomerOutlet: (id) => (dispatch) => {
		deleteCustomerOutlet(id).then((res) => {
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_CUSTOMER_OUTLET_SUCCESS,
					payload: {
						customerOutlets: res?.data?.outlets,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD CUSTOMER GROUP
	addCustomerGroup: (data) => (dispatch) => {
		dispatch({
			type: ADD_CUSTOMER_GROUP,
			payload: {
				addCustomerGroupLoading: true,
			},
		});

		addCustomerGroup(data).then((res) => {
			dispatch({
				type: ADD_CUSTOMER_GROUP,
				payload: {
					addCustomerGroupLoading: false,
				},
			});

			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: ADD_CUSTOMER_GROUP_SUCCESS,
					payload: {
						customerGroups: res?.data?.customerGroups,
						addedGroup: res?.data?.addedCustomerGroup
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL CUSTOMER GROUPS LIST
	getAllCustomerGroupsList: () => (dispatch) => {

		getAllCustomerGroupsList().then((res) => {

			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_CUSTOMER_GROUPS_LIST,
					payload: {
						customerGroupsList: res?.data?.customerGroups,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				// window.location = "/forbidden";
				toast.error("customer groups")
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//FETCH ALL CUSTOMER GROUPS
	getAllCustomerGroups: () => (dispatch) => {
		dispatch({
			type: GET_ALL_CUSTOMER_GROUPS,
			payload: {
				getCustomerGroupsLoading: true,
			},
		});
		getAllCustomerGroups().then((res) => {
			dispatch({
				type: GET_ALL_CUSTOMER_GROUPS,
				payload: {
					getCustomerGroupsLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_ALL_CUSTOMER_GROUPS_SUCCESS,
					payload: {
						customerGroups: res?.data?.customerGroups,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE CUSTOMER GROUP
	getCustomerGroup: (id) => (dispatch) => {
		dispatch({
			type: GET_CUSTOMER_GROUP,
			payload: {
				getCustomerGroupLoading: true,
			},
		});
		getCustomerGroup(id).then((res) => {
			dispatch({
				type: GET_CUSTOMER_GROUP,
				payload: {
					getCustomerGroupLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMER_GROUP_SUCCESS,
					payload: {
						currentCustomerGroup: res?.data?.customerGroup,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//UPDATE CUSTOMER GROUP
	updateCustomerGroup: (data, id, history) => (dispatch) => {
		dispatch({
			type: UPDATE_CUSTOMER_GROUP,
			payload: {
				editCustomerGroupLoading: true,
			},
		});

		updateCustomerGroup(data, id).then((res) => {
			dispatch({
				type: UPDATE_CUSTOMER_GROUP,
				payload: {
					editCustomerGroupLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: UPDATE_CUSTOMER_GROUP_SUCCESS,
					payload: {
						customerGroups: res?.data?.customerGroups,
					},
				});
				history.push("/customer-groups");
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE CUSTOMER GROUP
	deleteCustomerGroup: (id) => (dispatch) => {
		dispatch({
			type: DELETE_CUSTOMER_GROUP,
			payload: {
				deleteCustomerGroupLoading: true,
			},
		});
		deleteCustomerGroup(id).then((res) => {
			dispatch({
				type: DELETE_CUSTOMER_GROUP,
				payload: {
					deleteCustomerGroupLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_CUSTOMER_GROUP_SUCCESS,
					payload: {
						customerGroups: res?.data?.customerGroups,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//ADD CUSTOMER PHONE
	addCustomerPhone: (data) => (dispatch) => {
		dispatch({
			type: ADD_CUSTOMER_PHONE,
			payload: {
				addCustomerPhoneLoading: true,
			},
		});

		addCustomerPhone(data).then((res) => {
			dispatch({
				type: ADD_CUSTOMER_PHONE,
				payload: {
					addCustomerPhoneLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: ADD_CUSTOMER_PHONE_SUCCESS,
					payload: {
						customerPhones: res?.data?.customerPhones,
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	// EDIT CUSTOMER PHONE
	editCustomerPhone: (data, uuid) => (dispatch) => {
		dispatch({
			type: EDIT_CUSTOMER_PHONE,
			payload: {
				editCustomerPhoneLoading: true,
			},
		});
		editCustomerPhone(data, uuid).then((res) => {
			dispatch({
				type: EDIT_CUSTOMER_PHONE,
				payload: {
					editCustomerPhoneLoading: false,
				},
			});

			if (res?.status === 200) {
				dispatch({
					type: EDIT_CUSTOMER_PHONE_SUCCESS,
					payload: {
						customerPhones: res?.data?.customerPhones,
					},
				});
				toast.success(res?.data?.message || "Success", { theme: "colored" });
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET CUSTOMER PHONES
	getCustomerPhones: (data) => (dispatch) => {
		dispatch({
			type: GET_CUSTOMER_PHONES,
			payload: {
				getCustomerPhonesLoading: true,
			},
		});
		getCustomerPhones(data).then((res) => {
			dispatch({
				type: GET_CUSTOMER_PHONES,
				payload: {
					getCustomerPhonesLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMER_PHONES_SUCCESS,
					payload: {
						customerPhones: res?.data?.customerPhones,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//GET SINGLE CUSTOMER PHONE
	getCustomerPhone: (id) => (dispatch) => {
		dispatch({
			type: GET_CUSTOMER_PHONE,
			payload: {
				getCustomerPhoneLoading: true,
			},
		});
		getCustomerPhone(id).then((res) => {
			dispatch({
				type: GET_CUSTOMER_PHONE,
				payload: {
					getCustomerPhoneLoading: false,
				},
			});
			if (res?.status === 200) {
				dispatch({
					type: GET_CUSTOMER_PHONE_SUCCESS,
					payload: {
						customerCurrentPhone: res?.data?.customerPhone,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},

	//DELETE CUSTOMER PHONE
	deleteCustomerPhone: (id) => (dispatch) => {
		dispatch({
			type: DELETE_CUSTOMER_PHONE,
			payload: {
				deleteCustomerPhoneLoading: true,
			},
		});
		deleteCustomerPhone(id).then((res) => {
			dispatch({
				type: DELETE_CUSTOMER_PHONE,
				payload: {
					deleteCustomerPhoneLoading: false,
				},
			});
			if (res?.status === 200) {
				toast.success(res?.data?.message || "Success", { theme: "colored" });
				dispatch({
					type: DELETE_CUSTOMER_PHONE_SUCCESS,
					payload: {
						customerPhones: res?.data?.customerPhones,
					},
				});
			} else if (res?.status === 401) {
				window.location = "/session-ended";
			} else if (res?.status === 403) {
				window.location = "/forbidden";
			} else {
				toast.error(res?.data?.message || "error", { theme: "colored" });
			}
		});
	},


};

export default actions;
